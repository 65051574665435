import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { BuildersListKey } from '@core/enums/builders-key.enum';
import { NotificationService } from '@shared/modules/notification/notification.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AfterViewInit, ChangeDetectorRef, Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FetchActionsExecution, UpdateChangedItem } from '@store/general/general.actions';
import { DestroyBase } from '@core/base/destroy.class';
import { CapturumListRendererComponent } from '@capturum/builders/list-renderer';
import { CapturumBuilderActionService } from '@capturum/builders/core';
import { FinanceLot } from '@core/enums/lot.model';
import { takeUntil } from 'rxjs/operators';
import { BuilderActionType } from '@core/enums/builder-action-type.enum';
import { LotService } from '@core/api/lot.service';

@Component({
  selector: 'app-un-match-sales',
  templateUrl: './un-match-sales.component.html',
  styleUrls: ['./un-match-sales.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UnMatchSalesComponent extends DestroyBase implements AfterViewInit {
  public buildersKey: BuildersListKey = BuildersListKey.listLotsSalesOrders;
  public lot: FinanceLot;
  @ViewChild(CapturumListRendererComponent) public listRenderer: CapturumListRendererComponent;
  public selectedLines = [];

  constructor(
    private dialogRef: DynamicDialogRef,
    private notificationService: NotificationService,
    private store: Store,
    private translateService: TranslateService,
    private actions: Actions,
    private cdr: ChangeDetectorRef,
    private lotService: LotService,
    private actionService: CapturumBuilderActionService,
    private config: DynamicDialogConfig,
  ) {
    super();

    this.lot = this.config?.data?.item;
  }

  public ngAfterViewInit(): void {
    this.actions.pipe(ofActionSuccessful(UpdateChangedItem), takeUntil(this.destroy$)).subscribe((response) => {
      this.listRenderer.data.map((item) => {
        if (response.property && item?.id === response?.item?.id) {
          this.selectedLines.push({
            ...response.item,
            floriday_supply_line_id: this.lot.floriday_supply_line_id,
          });
        } else if (!response.property) {
          const index = this.selectedLines.findIndex((item) => {
            return item.id === response?.item?.id;
          });

          if (index > -1) {
            this.selectedLines.splice(index, 1);
          }
        }
      });

      this.cdr.detectChanges();
    });
  }

  public onClose(): void {
    this.dialogRef.close();
  }

  public onSave(): void {
    this.lotService.unMatchSales({ salesLines: this.selectedLines }).subscribe(() => {
      this.store.dispatch(new FetchActionsExecution(BuilderActionType.closePopup));

      this.notificationService.success(
        this.translateService.instant('toast.success.title'),
        this.translateService.instant('dxp.finance.lots.unmatch.success.message'),
      );

      this.onClose();
      this.actionService.broadcastActionExecuted(
        {
          options: { refresh: true },
          key: null,
          type: null,
        },
        null,
        null,
      );
    });
  }
}
