<div class="popup-content">
  <cpb-list-renderer
    class="white"
    [context]="{ floriday_supply_line_id: lot?.floriday_supply_line_id }"
    [key]="buildersKey"></cpb-list-renderer>
</div>

<div class="popup-actions">
  <cap-button styleClass="secondary mt-3" [label]="'button.cancel' | translate" (onClick)="onClose()"></cap-button>

  <cap-button
    appPreventDoubleClick
    styleClass="primary ml-2 mt-3"
    [class.pe-none]="!selectedLines.length"
    [disabled]="!selectedLines.length"
    [label]="'dxp.finance.lots.button.unmatch' | translate"
    (clickEvent)="onSave()">
  </cap-button>
</div>
